body {
	font-size: 16px;
	overflow: hidden;
	heigth: 100vh;
}

.main-body-div {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.main-content {
	overflow: auto;
	height: 100vh;
	flex: 1;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}
img{
 outline : none;
 }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

.cover-image {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/login_splash.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}



/* jhipster-needle-css-add-main JHipster will add new css style */

.navbar .nav  .nav-item {
	margin-left: 1rem;
}

.navbar .nav  .nav-item > a {
	padding: 0;
}

.navbar-nav .dropdown-menu {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.navbar-nav > li > .dropdown-menu {
    border-radius: 0.25rem;
}

.navbar-nav {
	margin-right: 0.5rem;
}

.nav > li > a:hover, .nav > li > a:focus,
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #f4f5f7;
}

.content-wrapper {
	background-color: #ffffff;
}

label {
	font-weight: initial;
}

th label {
    font-weight: bold;
}

h4 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.dataset-header {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   line-height: 21px;
   max-height: 42px;
}

.dataset-description {
   overflow: hidden;
   display: flex;
   flex-direction: row;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   line-height: 1.1em;
   max-height: 2.2em;
}

/* padding on save, copy, new buttons in share, download screens is too much */
.list-inline > li.item2 {
    padding-right: 0px;
    padding-left: 0px;
}

.btn {
    line-height: 1.25;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
}

.chosen-container {
	font-size: 1em;
}

.chosen-container .chosen-single {
	height: 34px;
    line-height: 1.8;
}

.chosen-container-single .chosen-single div b {
    background-position: 0px 8px;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 8px;
}

.chosen-container-single .chosen-single abbr {
    top: 10px;
}

.bootstrap-duallistbox-container .box1, .bootstrap-duallistbox-container .box2 {
    border: 1px solid #0078a6;
    border-radius: 3px;
    padding-bottom: 5px;
    margin-left: 10px;
}

.bootstrap-duallistbox-container .boxhead {
    background-color: #0078a6;
    color: #fff;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 1.1em;
}

.bootstrap-duallistbox-container {
    width: 100% !important;
    padding-left: 45px;
}

.bootstrap-duallistbox-container select {
    border-color: #fff;
}

.bootstrap-duallistbox-container .btn-xs,
.bootstrap-duallistbox-container .btn-xs:hover,
.bootstrap-duallistbox-container .btn-xs:active
{
	font-size: 10px;
}

.dropdown-toggle3::after {
    vertical-align: middle;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
    box-sizing: inherit;
}

.dropdown-menu .divider {
    margin: 0;
}

.box-title.text-left {
	text-align: left;
}

.has-feedback label ~ .form-control-feedback.pull-down {
  top: 25px !important;
}

.form-control-feedback.move-right{
	right : 10px;
}

.dropzone .dz-preview .dz-error-message {
     top: 150px!important;
}

.dropzone .dz-preview .dz-progress {
     top: 100px!important;
}

.no-outline{
	outline:none;
}

.nav {
	cursor: default;
}

.wrapped-dataset-label {
	word-break: break-all;
}

.shared-arrow-text {
	word-break: break-all;
}

.break-long-text {
	word-break: break-all;
}

.dropdown-menu > li > .btn-sm {
	margin: 0 6em;
}

.vertical-align {
    display: flex;
    align-items: center;
}

div.auto-complete-container {
	border: 1px solid #0078a6;
}

div.auto-complete-container ul.auto-complete-results li.auto-complete-item {
	font-weight: initial;
}

div.auto-complete-container ul.auto-complete-results li.auto-complete-item:hover {
	background-color: #0078a6;
}

div.auto-complete-container ul.auto-complete-results li.auto-complete-item-selected {
	background-color: #0078a6;
}


/* ==========================================================================
tokachi - jquery tokenized input
========================================================================== */

div.tokenized-input {
    color: black;
    text-shadow: none;
    background: white;
    margin: 10px 0px 2px 0px;
    padding: 5px 5px 5px 5px;
    min-height: 25px;
    border: 1px solid #ECEFF1;
}

div.tokenized-input .sub {
    color: #e1e3ff;
    font-size: 10pt;
}

.tokchi-dropdown {
    box-sizing: border-box;
    font-family: sans-serif;
    z-index: 2000;
    background: #fff;
    cursor: pointer;
    min-width: 250px;
    max-height: 190px;
    overflow-y: auto;
    font-size: 14px;
    color: #525252;
    list-style: none;
    padding: 0;
    box-shadow: 3px 3px 15px rgba(0,0,0,.8);
}

.tokchi-dropdown-item {
    box-sizing: border-box;
    margin: 0;
    padding: 8px 5px;
    margin: 0;
    background-color: #fff;
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tokchi-dropdown-item div {
    font-size: 12px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tokchi-dropdown-item-selected {
    background-color: #0078a6;
    color: #fff;
}

.tokchi-token {
    font-family: sans-serif;
    overflow: hidden;
    display: inline-block;
    height: auto !important;
    height: 20px;
    margin: 1px;
    padding: 3px 10px;
    background-color: #F5F5F5;
    color: #4B4F54;
    cursor: default;
    font-size: 14px;
    border: solid 1px #D3DEEC;
    border-radius: 10px;
    word-break: break-all;
    vertical-align: middle;
}

.tokchi-token-close-button {
    vertical-align: middle;
    color: #4B4F54;
    font-weight: bold;
    opacity: 0.5;
    font-size: 12px;
    padding: 0;
    margin-left: 5px;
    cursor: pointer;
}

.dataset-tag {
    overflow: hidden;
    display: inline-block;
    height: auto !important;
    height: 15px;
    margin: 1px 2px;
    padding: 2px 6px;
    background-color: #F5F5F5;
    color: #4B4F54;
    cursor: default;
    font-size: 14px;
    border: solid 1px #D3DEEC;
    border-radius: 10px;
    word-break: break-all;
    vertical-align: middle;
}

.dataset-tags-list {
	margin: 2px;
}

.dataset-search-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
    border: 1px solid #dddddd;
    background: #ffffff;
    color: #333333;
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: 0;
}

.dataset-search-dropdown li {
}

li.dataset-search-category {
    color: #00adef;
    font-weight: bold;
    padding: 2px;
}

li.dataset-search-item {
    border: 1px solid #ffffff;
	padding: 1px 1px 1px 0.5em;
}

li.dataset-search-item .recent-search {
    display: flex;
    flex-direction: row;
}

li.dataset-search-item .recent-search .recent-search-label {
    flex: 1;
}

.dataset-search-dropdown .ui-state-active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: #ffffff;
}

[type="checkbox"]:not(:checked).star, [type="checkbox"]:checked.star {
    visibility: hidden;
    cursor: pointer;
    position: relative;
    top: -5px;
    left: -2px;
    opacity: 100;
    color: #ecda35;
}
.dataset-search-dropdown .star:before {
   content: "\2606";
   visibility: visible;
}
.dataset-search-dropdown .star:checked:before {
   content: "\2605";
}

.dataset-search-dropdown .ui-state-active [type="checkbox"]:not(:checked).star, .dataset-search-dropdown .ui-state-active [type="checkbox"]:checked.star {
	color: #ffffff;
}

div.dataset-query-box {
    width: 700px;
    display: flex;
    flex-direction: row;
}

div.dataset-query-box div.input-delete-icon {
    flex: 1;
}

div.dataset-query-box .searchBox {
    margin-top: 0px;
}

div.input-delete-icon {
    position: relative;
    display: flex;
    flex-direction: row;
}
div.input-delete-icon span {
    position: absolute;
    display: block;
    top: 9px;
    right: 8px;
    width: 16px;
    height: 16px;
    background: url('../images/input-delete.png') 0 -690px;
    background-position: center;
    cursor: pointer;
    outline: none;
}
div.input-delete-icon input {
    padding-right: 20px;
    box-sizing: border-box;
    flex: 1;
}


.tag-admin-category {
	margin: 12px 0px 4px 0px;
}

.tag-admin-tag {
    font-family: sans-serif;
    overflow: hidden;
    display: inline-block;
    height: auto !important;
    height: 20px;
    margin: 1px;
    padding: 3px 10px;
    background-color: #F5F5F5;
    color: #4B4F54;
    cursor: default;
    font-size: 14px;
    border: solid 1px #D3DEEC;
    border-radius: 10px;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;
}

.tag-admin-tag .editor {
	display: inline-block;
	overflow: hidden;
	width: 500px;
}
.tag-admin-tag input {
	width: 100%;
}

.tag-admin-tag-body {
	display: inline-block;
}

.tag-admin-tag-usage-count {
    display: inline-block;
	vertical-align: middle;
    color: #4B4F54;
    font-weight: bold;
    opacity: 0.5;
    font-size: 12px;
    padding: 0;
    margin-left: 5px;
    cursor: pointer;
    outline: none;
}

.tag-admin-tag-usage-divider {
    border-left: solid 1px #595e65;
    padding: 0px 0px 0px 8px;
}

.tag-admin-arrow {
    display: inline-block;
    vertical-align: middle;
	font-size: 0.5em;
}

.tag-admin-top-children {
    margin: 0px 0px 0px -24px;
}

.tag-admin-children {
	margin: 1px 0px 6px 24px;
}

.tag-droppable-hover, tag-droppable-active {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: #ffffff;
}

.tag-admin-tag.active-tag {
    border: 1px solid #003eff;
    background: #007fff;
    font-weight: normal;
    color: #ffffff;
}

.tag-admin-tag.inactive-tag {
    border: 1px solid #595e65;
    background: #b3b3b3;
    font-weight: normal;
    color: #ffffff;
}

.tag-admin-drag-handle {
    display: inline-block;
    vertical-align: middle;
}

.fa.fa-grip:before {
    content: "\f142 \202F \f142 \202F \f142";
    color: #4B4F54;
    cursor: move;
}

.tag-content {
	outline: none;
	cursor: default;
}

.flexbox-column {
	display: flex;
	flex-direction: column;
}

.flexbox-row {
    display: flex;
    flex-direction: row;
}

div.flex-scroll {
    margin-top: 0px;
    height: 400px;
    overflow-x: auto;
    overflow-y: scroll;
    margin-bottom: 10px;
    border: 4px solid #fff;
    flex: 1;
}

@media (min-height: 1050px) {
	div.flex-scroll {
        height: 600px;
    }
}

.right div.tooltip-inner {
	width: 500px;
}

i.dataset-header-icon {
    font-weight: normal;
    font-size: 90%;
    line-height: 1;
    color: #777;
}

.dataset-label {
	flex: 1;
	overflow: hidden;
	vertical-align: bottom;
	width: 100%;
}

.dataset-label-internal {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dataset-description-internal {
	color: #546e7a;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    font-size: .9em;
    margin: 0 auto;
    white-space: nowrap;
}

.dataset-top-download {
    overflow: hidden;
}

.dataset-top-download-internal {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

 .video-js .vjs-control-bar {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
 }

 /** Date Time Picker **/
 ul.datetime-picker-dropdown {
    margin-left: 0px;
 }

/* ==========================================================================
icon colors
========================================================================== */
.icon-color-orange {
	color: #FFA500
}

.report-container {
	margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.report-grid-header-table {
	width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    table-layout: fixed;
}

.nowrap {
    white-space: nowrap;
}

.hover-grid .hover-grid-row {
  border-top: 1px solid #ECEFF1;
  border-bottom: 1px solid #ECEFF1;
  margin-bottom: 0;
}
.hover-grid .hover-grid-row:hover {
  background-color: #f9fbfc;
}
.hover-grid .hover-grid-cell {
    min-height: 30px;
    overflow-x: hidden;
}

.overflow-x-hidden {
	overflow-x: hidden;
}

.small-radio-box {
	text-align: right;
}

.small-radio-box > label {
	margin-top: 4px;
    vertical-align: top;
}

.small-radio-box > div {
    display: inline-block;
    text-align: left;
}

/* ==========================================================================
font-awesome icon color
========================================================================== */
.fa-pencil-user-share:hover{
	background: #0E92C7;
}

i.fa-pencil-user-share{
	margin-top: 0;
}

.fa-archive{
	color: #ed4c1c;
}
